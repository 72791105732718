import create from "zustand";
import {
	tBreadcrumbs,
	tCollines,
	tCommunes,
	tFilter,
	tProvinces,
	tRatingCounts,
	tStore,
} from "../types";

export const useStore = create<tStore>((set) => ({
	provinces: [],
	communes: [],
	collines: [],
	ratingCounts: { red: 0, green: 0, orange: 0 },

	breadcrumbs: [],

	filter: {
		isFiltering: false,
		collines: [],
		breadcrumbs: [],
	},

	filterRatings: [],
	setFilterRatings: (filter: "GREEN" | "RED" | "ORANGE") =>
		set((state) => ({ filterRatings: [...state.filterRatings, filter] })),

	removeFilterRatings: (filter) =>
		set((state) => {
			state.filterRatings = state.filterRatings.filter(
				(filterToRemove) => filter !== filterToRemove
			);
		}),

	setProvinces: (provinces: tProvinces) =>
		set(() => ({
			provinces: provinces,
		})),

	setCommunes: (communes: tCommunes) =>
		set(() => ({
			communes: communes,
		})),

	setCollines: (collines: tCollines | []) =>
		set((state) => {
			console.log(state.filter);
			return { collines };
		}),

	setRatingCounts: (ratingCounts: tRatingCounts) =>
		set(() => ({ ratingCounts })),

	setFilter: (filter: tFilter) =>
		set((state) => {
			return { filter };
		}),

	setBreadcrumbs: (breadcrumbs: tBreadcrumbs) => set(() => ({ breadcrumbs })),

	burger: { isOpened: false },
	setBurger: (value: boolean) => set(() => ({ burger: { isOpened: value } })),
}));

/**
 * .length
				? communes.sort((prev, next) =>
						(prev as any).name > (next as any).name ? -1 : 1
				  )
				: []
 */

import { Navbar } from "@mantine/core";
import React from "react";
import { useStore } from "../../store";
import { SidebarFooter } from "./sidebar-footer";
import { SidebarHeader } from "./sidebar-header";
import { MainNav } from "./sidebar-main-nav";

export function Sidebar() {
	const { burger } = useStore((store) => store);

	return (
		<Navbar
			width={{ sm: 300, lg: 300 }}
			height="100vh"
			p={0}
			hidden={!burger.isOpened}
			hiddenBreakpoint="sm"
		>
			<SidebarHeader />
			<MainNav />
			<SidebarFooter />
		</Navbar>
	);
}

import React from "react";
import { render } from "react-dom";
import { App } from "./app";
import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root") as HTMLElement;

const Root = () => (
	<React.StrictMode>
		<App />
	</React.StrictMode>
);

render(<Root />, rootElement);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

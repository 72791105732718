import { Group, Loader, Text } from "@mantine/core";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { AppLayout, Toolbar } from "../components";
import { useGetDataQuery } from "../graphql";
import { useStore } from "../store";
import { tCollines, tCommunes, tProvinces } from "../types";
import { MapMemo } from "./map";

export const AppView = () => {
	const store = useStore((store) => store);

	const { setProvinces, setCommunes, setCollines, setBreadcrumbs } = store;
	const { data, loading, error } = useGetDataQuery();

	useEffect((): any => {
		if (!loading) {
			setProvinces(data?.provinces as tProvinces);
			setCommunes(data?.communes as tCommunes);

			setBreadcrumbs([
				{ title: "Burundi" },
				{ title: `${data?.provinces?.length} Provinces` },
				{ title: `${data?.communes?.length} Communes` },
				{ title: `${data?.collines?.length}  Cooperatives` },
			]);

			return setCollines(data?.collines as tCollines);
		}

		return undefined;
	}, [
		data?.collines,
		data?.communes,
		data?.provinces,
		loading,
		setBreadcrumbs,
		setCollines,
		setCommunes,
		setProvinces,
	]);

	if (loading)
		return (
			<Group
				align={"center"}
				position="center"
				sx={() => ({
					height: "100vh",
					width: "100%",
				})}
			>
				<Group position="center" align={"center"}>
					<Loader size="xl" />
					<Text>Chargement...</Text>
				</Group>
			</Group>
		);
	if (error) return <p>Error: {error?.message}</p>;

	return (
		<AppLayout>
			<Helmet>
				<title>Cooperatives Sangwe</title>
				<meta name="description" content="Cooperatives Sangwe" />
			</Helmet>
			<MapMemo />
			<Toolbar />
		</AppLayout>
	);
};

import { Box, Stack } from "@mantine/core";
import "leaflet-defaulticon-compatibility";
import "leaflet-defaulticon-compatibility/dist/leaflet-defaulticon-compatibility.css";
import "leaflet/dist/leaflet.css";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useStore } from "../store";
import { tRating } from "../types";
import { getIcon } from "../utils";

const Map = () => {
	const { collines, filterRatings } = useStore((store) => store);

	const getCollines = (filterRatings: string[]) => {
		if (filterRatings.length) {
			return collines
				?.filter(({ rating }) =>
					filterRatings.includes(rating as string)
				)
				.map((colline) => (
					<Marker
						icon={getIcon(colline.rating as tRating)}
						position={[
							(colline?.latidude as number) || -0,
							(colline?.longitude as number) || 0,
						]}
						key={colline.id}
					>
						<Popup maxWidth={200}>
							<Stack spacing={3}>
								<Box>
									Province:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.commune?.province?.name ||
											"N/A"}
									</Box>
								</Box>

								<Box>
									Commune:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.commune?.name || "N/A"}
									</Box>
								</Box>

								<Box>
									Colline:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.name || "N/A"}
									</Box>
								</Box>

								<Box>
									Coopérative:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.cooperativeName || "N/A"}
									</Box>
								</Box>

								<Box>
									Représentant:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.adminName || "N/A"}
									</Box>
								</Box>

								<Box>
									Tél:{" "}
									<Box
										component="span"
										sx={() => ({ fontWeight: "bold" })}
									>
										{colline.adminPhone || "N/A"}
									</Box>
								</Box>
							</Stack>
						</Popup>
					</Marker>
				));
		}

		return collines.map((colline) => (
			<Marker
				icon={getIcon(colline.rating as tRating)}
				position={[
					(colline?.latidude as number) || -0,
					(colline?.longitude as number) || 0,
				]}
				key={colline.id}
			>
				<Popup maxWidth={200}>
					<Stack spacing={3}>
						<Box>
							Province:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.commune?.province?.name || "N/A"}
							</Box>
						</Box>

						<Box>
							Commune:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.commune?.name || "N/A"}
							</Box>
						</Box>

						<Box>
							Colline:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.name || "N/A"}
							</Box>
						</Box>

						<Box>
							Coopérative:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.cooperativeName || "N/A"}
							</Box>
						</Box>

						<Box>
							Représentant:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.adminName || "N/A"}
							</Box>
						</Box>

						<Box>
							Tél:{" "}
							<Box
								component="span"
								sx={() => ({ fontWeight: "bold" })}
							>
								{colline.adminPhone || "N/A"}
							</Box>
						</Box>
					</Stack>
				</Popup>
			</Marker>
		));
	};

	return (
		<div style={{ width: "100%", height: "100vh" }}>
			<MapContainer
				center={[-3.3896077, 29.9255829]}
				zoom={9.3}
				style={{ height: "100%", width: "100%" }}
			>
				<TileLayer
					attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
					// url={`https://api.mapbox.com/styles/v1/mapbox/streets-v11/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoibXVjb3J0IiwiYSI6ImNreGVvMzRtZTBwc2YzMnF2c252cTB0ZGwifQ.Lv9rnGrkLVeFr9DPylQmjQ`}
					url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
				/>

				{getCollines(filterRatings)}
			</MapContainer>
		</div>
	);
};

export const MapMemo = React.memo(Map);

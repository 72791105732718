import { Global, MantineProvider } from "@mantine/core";
import { NotificationsProvider } from "@mantine/notifications";
import { ReactNode } from "react";

type ThemeProviderProps = {
	children: ReactNode;
};

export const ThemeProvider = ({ children }: ThemeProviderProps) => {
	return (
		<MantineProvider
			withGlobalStyles
			withNormalizeCSS
			theme={{
				black: "#343A40",
				primaryColor: "brand",
				fontFamily: "'Source Sans Pro', sans-serif",
				headings: {
					fontFamily: "'Source Sans Pro', sans-serif",
				},

				colors: {
					brand: [
						"#eceaff",
						"#c9c5f0",
						"#a29fe0",
						"#7b79d3",
						"#5455c5",
						"#3a3fab",
						"#2c3486",
						"#1f2161",
						"#12113c",
						"#07041a",
					],
				},
			}}
		>
			<Global
				styles={() => ({
					".leaflet-container": {
						zIndex: "1 !important",
					},
				})}
			/>
			<NotificationsProvider position="top-right">
				{children}
			</NotificationsProvider>
		</MantineProvider>
	);
};

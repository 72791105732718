import { Box, Burger, MediaQuery } from "@mantine/core";
import React from "react";
import { useStore } from "../../store";

export const MenuBurger = () => {
	const { burger, setBurger } = useStore((store) => store);

	const title = burger.isOpened ? "Close navigation" : "Open navigation";

	return (
		<MediaQuery largerThan="sm" styles={{ display: "none" }}>
			<Box
				sx={(theme) => ({
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					position: "absolute",
					top: 60,
					zIndex: 1001,
					backgroundColor: "white",
					borderRadius: theme.radius.sm,
					right: 10,
					width: 40,
					height: 40,
					boxShadow: theme.shadows.sm,
				})}
			>
				<Burger
					opened={burger.isOpened}
					onClick={() => setBurger(!burger.isOpened)}
					title={title}
				/>
			</Box>
		</MediaQuery>
	);
};

import { Box, Group, Image, Navbar, Text } from "@mantine/core";

export const SidebarFooter = () => {
	return (
		<Navbar.Section
			sx={(theme) => ({
				padding: `${theme.spacing.xs}px 0`,

				borderTop: `1px solid ${theme.colors.gray[1]}`,
			})}
		>
			<Group
				spacing={3}
				align="center"
				position="center"
				sx={() => ({
					img: {
						height: "20px",
					},
				})}
			>
				<Box>
					<Text
						sx={(theme) => ({
							color: theme.colors.gray[5],
							fontSize: 12,
						})}
					>
						Conçu par{" "}
					</Text>
				</Box>
				<Box
					component="a"
					href="https://www.nemidis.com"
					target="_blank"
					sx={() => ({
						height: "23px",
					})}
				>
					<Image src="/nemidis.webp" />
				</Box>
			</Group>
		</Navbar.Section>
	);
};

import {
	Breadcrumbs,
	Checkbox,
	Group,
	Paper,
	Stack,
	Text,
} from "@mantine/core";
import { useStore } from "../../store";

export const Toolbar = () => {
	const { ratingCounts, breadcrumbs, setFilterRatings, removeFilterRatings } =
		useStore((store) => store);

	const items = breadcrumbs.map((item, index) => (
		<Text
			style={{ fontWeight: index === 0 ? "bold" : "normal" }}
			key={index}
		>
			{item.title}
		</Text>
	));

	return (
		<Paper
			sx={(theme) => ({
				bottom: 0,
				background: "white",
				border: `1px solid ${theme.colors.gray[2]}`,
				display: "flex",
				margin: "0 auto",
				zIndex: 1000,
				position: "fixed",
				maxWidth: "95%",

				left: "50%",
				width: "auto",
				height: "auto",

				// left: "56%",
				// backdropFilter: "blur(6px)",
				// background: "rgba(255, 255, 255, 0.80)",
				// position: "fixed",
				// minWidth: "500px",
				// maxWidth: "900px",
				// marginBottom: "50px",
				transform: "translate(-50%, -50%)",
				overflow: "auto",
			})}
			radius="md"
			p="lg"
		>
			<Stack align={"center"}>
				<Breadcrumbs separator="-">{items}</Breadcrumbs>

				<Group>
					<Checkbox
						styles={({ colors }) => ({
							label: {
								color: colors.red[7],
								fontWeight: 800,
							},
						})}
						label={`Rouges ${ratingCounts.red}`}
						color="red"
						onChange={(event) => {
							const isChecked = event.currentTarget.checked;

							if (isChecked) return setFilterRatings("RED");
							else return removeFilterRatings("RED");
						}}
					/>
					<Checkbox
						styles={({ colors }) => ({
							label: {
								color: colors.orange[7],
								fontWeight: 800,
							},
						})}
						label={`Orange ${ratingCounts.orange}`}
						color="orange"
						onChange={(event) => {
							const isChecked = event.currentTarget.checked;

							if (isChecked) return setFilterRatings("ORANGE");
							else return removeFilterRatings("ORANGE");
						}}
					/>
					<Checkbox
						styles={({ colors }) => ({
							label: {
								color: colors.green[7],
								fontWeight: 800,
							},
						})}
						label={`Vert ${ratingCounts.green}`}
						color="green"
						onChange={(event) => {
							const isChecked = event.currentTarget.checked;

							if (isChecked) return setFilterRatings("GREEN");
							else return removeFilterRatings("GREEN");
						}}
					/>
				</Group>
			</Stack>
		</Paper>
	);
};

import { ApolloProvider } from "@apollo/client";
import React from "react";
import { client } from "../config";
import { ThemeProvider } from "../theme";
import { AppView } from "../views";

export function App() {
	return (
		<ApolloProvider client={client}>
			<ThemeProvider>
				<AppView />
			</ThemeProvider>
		</ApolloProvider>
	);
}

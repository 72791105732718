import { Box, Paper, PaperProps, Text } from "@mantine/core";
import { tColline } from "../types";
import { formatMoney } from "../utils";

type CollineProps = tColline & PaperProps<"div">;

export const Colline = (props: CollineProps) => {
	const {
		cooperativeName,
		NIF,
		adminName,
		RC,
		// adminPhone,
		name,
		books,
		accounting,
		treasury,
		amount,
		achievable,
		total,
		description,
		rating,
	} = props;

	const getRating = (rate: any) => {
		switch (rate) {
			case "GREEN":
				return { color: "green", label: "Vert" };

			case "ORANGE":
				return { color: "orange", label: "Orange" };

			case "RED":
				return { color: "red", label: "Rouge" };
		}
	};
	return (
		<Paper
			// shadow={"0px 20px 38px -7px rgba(22, 107, 255, 0.1)"}
			{...props}
		>
			<Box>
				<Text mt={3} size="sm">
					<span>Nom de la coopérative:</span>{" "}
					<strong>{cooperativeName || "N/A"}</strong>
				</Text>

				<Text mt={3} size="sm">
					<span>NIF:</span> <strong>{NIF || "N/A"}</strong>
				</Text>

				<Text mt={3} size="sm">
					<span>RC:</span> <strong>{RC || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Nom du représentant:</span>{" "}
					<strong>{adminName || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Tél:</span>{" "}
					{/* <strong>{adminPhone || "N/A"}</strong> */}
				</Text>
				<Text mt={3} size="sm">
					<span>Colline:</span> <strong>{name || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Livre de caisse:</span>{" "}
					<strong>{books || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Piece comptable:</span>{" "}
					<strong>{accounting || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Montat utilise: </span>{" "}
					<strong>{formatMoney(amount) || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Tresorerie:</span>{" "}
					<strong>{formatMoney(treasury) || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Realisables:</span>{" "}
					<strong>{formatMoney(achievable) || "N/A"}</strong>
				</Text>

				<Text mt={3} size="sm">
					<span>Total:</span>{" "}
					<strong>{formatMoney(total) || "N/A"}</strong>
				</Text>
				<Text mt={3} size="sm">
					<span>Classement:</span>{" "}
					<strong
						style={{
							color: `${getRating(rating as any)?.color}`,
						}}
					>
						{getRating(rating as any)?.label || "N/A"}
					</strong>
				</Text>

				<Text mt={3} size="sm">
					<span>Description:</span> {description || "N/A"}
				</Text>
			</Box>
		</Paper>
	);
};

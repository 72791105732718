import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
	[K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
	[SubKey in K]: Maybe<T[SubKey]>;
};
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	/** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
	JSON: any;
};

export type AuthenticatedItem = User;

export type Colline = {
	__typename?: "Colline";
	NIF?: Maybe<Scalars["String"]>;
	RC?: Maybe<Scalars["String"]>;
	accounting?: Maybe<Scalars["String"]>;
	achievable?: Maybe<Scalars["Float"]>;
	adminName?: Maybe<Scalars["String"]>;
	adminPhone?: Maybe<Scalars["String"]>;
	amount?: Maybe<Scalars["Float"]>;
	books?: Maybe<Scalars["String"]>;
	commune?: Maybe<Commune>;
	cooperativeName?: Maybe<Scalars["String"]>;
	description?: Maybe<Scalars["String"]>;
	id: Scalars["ID"];
	latidude?: Maybe<Scalars["Float"]>;
	longitude?: Maybe<Scalars["Float"]>;
	name?: Maybe<Scalars["String"]>;
	rating?: Maybe<Scalars["String"]>;
	total?: Maybe<Scalars["Float"]>;
	treasury?: Maybe<Scalars["Float"]>;
};

export type CollineCreateInput = {
	NIF?: InputMaybe<Scalars["String"]>;
	RC?: InputMaybe<Scalars["String"]>;
	accounting?: InputMaybe<Scalars["String"]>;
	achievable?: InputMaybe<Scalars["Float"]>;
	adminName?: InputMaybe<Scalars["String"]>;
	adminPhone?: InputMaybe<Scalars["String"]>;
	amount?: InputMaybe<Scalars["Float"]>;
	books?: InputMaybe<Scalars["String"]>;
	commune?: InputMaybe<CommuneRelateToOneForCreateInput>;
	cooperativeName?: InputMaybe<Scalars["String"]>;
	description?: InputMaybe<Scalars["String"]>;
	latidude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
	rating?: InputMaybe<Scalars["String"]>;
	total?: InputMaybe<Scalars["Float"]>;
	treasury?: InputMaybe<Scalars["Float"]>;
};

export type CollineManyRelationFilter = {
	every?: InputMaybe<CollineWhereInput>;
	none?: InputMaybe<CollineWhereInput>;
	some?: InputMaybe<CollineWhereInput>;
};

export type CollineOrderByInput = {
	NIF?: InputMaybe<OrderDirection>;
	RC?: InputMaybe<OrderDirection>;
	accounting?: InputMaybe<OrderDirection>;
	achievable?: InputMaybe<OrderDirection>;
	adminName?: InputMaybe<OrderDirection>;
	adminPhone?: InputMaybe<OrderDirection>;
	amount?: InputMaybe<OrderDirection>;
	books?: InputMaybe<OrderDirection>;
	cooperativeName?: InputMaybe<OrderDirection>;
	description?: InputMaybe<OrderDirection>;
	id?: InputMaybe<OrderDirection>;
	latidude?: InputMaybe<OrderDirection>;
	longitude?: InputMaybe<OrderDirection>;
	name?: InputMaybe<OrderDirection>;
	rating?: InputMaybe<OrderDirection>;
	total?: InputMaybe<OrderDirection>;
	treasury?: InputMaybe<OrderDirection>;
};

export type CollineRelateToManyForCreateInput = {
	connect?: InputMaybe<Array<CollineWhereUniqueInput>>;
	create?: InputMaybe<Array<CollineCreateInput>>;
};

export type CollineRelateToManyForUpdateInput = {
	connect?: InputMaybe<Array<CollineWhereUniqueInput>>;
	create?: InputMaybe<Array<CollineCreateInput>>;
	disconnect?: InputMaybe<Array<CollineWhereUniqueInput>>;
	set?: InputMaybe<Array<CollineWhereUniqueInput>>;
};

export type CollineUpdateArgs = {
	data: CollineUpdateInput;
	where: CollineWhereUniqueInput;
};

export type CollineUpdateInput = {
	NIF?: InputMaybe<Scalars["String"]>;
	RC?: InputMaybe<Scalars["String"]>;
	accounting?: InputMaybe<Scalars["String"]>;
	achievable?: InputMaybe<Scalars["Float"]>;
	adminName?: InputMaybe<Scalars["String"]>;
	adminPhone?: InputMaybe<Scalars["String"]>;
	amount?: InputMaybe<Scalars["Float"]>;
	books?: InputMaybe<Scalars["String"]>;
	commune?: InputMaybe<CommuneRelateToOneForUpdateInput>;
	cooperativeName?: InputMaybe<Scalars["String"]>;
	description?: InputMaybe<Scalars["String"]>;
	latidude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
	rating?: InputMaybe<Scalars["String"]>;
	total?: InputMaybe<Scalars["Float"]>;
	treasury?: InputMaybe<Scalars["Float"]>;
};

export type CollineWhereInput = {
	AND?: InputMaybe<Array<CollineWhereInput>>;
	NIF?: InputMaybe<StringFilter>;
	NOT?: InputMaybe<Array<CollineWhereInput>>;
	OR?: InputMaybe<Array<CollineWhereInput>>;
	RC?: InputMaybe<StringFilter>;
	accounting?: InputMaybe<StringFilter>;
	achievable?: InputMaybe<FloatNullableFilter>;
	adminName?: InputMaybe<StringFilter>;
	adminPhone?: InputMaybe<StringFilter>;
	amount?: InputMaybe<FloatNullableFilter>;
	books?: InputMaybe<StringFilter>;
	commune?: InputMaybe<CommuneWhereInput>;
	cooperativeName?: InputMaybe<StringFilter>;
	description?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	latidude?: InputMaybe<FloatNullableFilter>;
	longitude?: InputMaybe<FloatNullableFilter>;
	name?: InputMaybe<StringFilter>;
	rating?: InputMaybe<StringNullableFilter>;
	total?: InputMaybe<FloatNullableFilter>;
	treasury?: InputMaybe<FloatNullableFilter>;
};

export type CollineWhereUniqueInput = {
	id?: InputMaybe<Scalars["ID"]>;
};

export type Commune = {
	__typename?: "Commune";
	collines?: Maybe<Array<Colline>>;
	collinesCount?: Maybe<Scalars["Int"]>;
	id: Scalars["ID"];
	latitude?: Maybe<Scalars["Float"]>;
	longitude?: Maybe<Scalars["Float"]>;
	name?: Maybe<Scalars["String"]>;
	province?: Maybe<Province>;
};

export type CommuneCollinesArgs = {
	orderBy?: Array<CollineOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: CollineWhereInput;
};

export type CommuneCollinesCountArgs = {
	where?: CollineWhereInput;
};

export type CommuneCreateInput = {
	collines?: InputMaybe<CollineRelateToManyForCreateInput>;
	latitude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
	province?: InputMaybe<ProvinceRelateToOneForCreateInput>;
};

export type CommuneManyRelationFilter = {
	every?: InputMaybe<CommuneWhereInput>;
	none?: InputMaybe<CommuneWhereInput>;
	some?: InputMaybe<CommuneWhereInput>;
};

export type CommuneOrderByInput = {
	id?: InputMaybe<OrderDirection>;
	latitude?: InputMaybe<OrderDirection>;
	longitude?: InputMaybe<OrderDirection>;
	name?: InputMaybe<OrderDirection>;
};

export type CommuneRelateToManyForCreateInput = {
	connect?: InputMaybe<Array<CommuneWhereUniqueInput>>;
	create?: InputMaybe<Array<CommuneCreateInput>>;
};

export type CommuneRelateToManyForUpdateInput = {
	connect?: InputMaybe<Array<CommuneWhereUniqueInput>>;
	create?: InputMaybe<Array<CommuneCreateInput>>;
	disconnect?: InputMaybe<Array<CommuneWhereUniqueInput>>;
	set?: InputMaybe<Array<CommuneWhereUniqueInput>>;
};

export type CommuneRelateToOneForCreateInput = {
	connect?: InputMaybe<CommuneWhereUniqueInput>;
	create?: InputMaybe<CommuneCreateInput>;
};

export type CommuneRelateToOneForUpdateInput = {
	connect?: InputMaybe<CommuneWhereUniqueInput>;
	create?: InputMaybe<CommuneCreateInput>;
	disconnect?: InputMaybe<Scalars["Boolean"]>;
};

export type CommuneUpdateArgs = {
	data: CommuneUpdateInput;
	where: CommuneWhereUniqueInput;
};

export type CommuneUpdateInput = {
	collines?: InputMaybe<CollineRelateToManyForUpdateInput>;
	latitude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
	province?: InputMaybe<ProvinceRelateToOneForUpdateInput>;
};

export type CommuneWhereInput = {
	AND?: InputMaybe<Array<CommuneWhereInput>>;
	NOT?: InputMaybe<Array<CommuneWhereInput>>;
	OR?: InputMaybe<Array<CommuneWhereInput>>;
	collines?: InputMaybe<CollineManyRelationFilter>;
	id?: InputMaybe<IdFilter>;
	latitude?: InputMaybe<FloatNullableFilter>;
	longitude?: InputMaybe<FloatNullableFilter>;
	name?: InputMaybe<StringFilter>;
	province?: InputMaybe<ProvinceWhereInput>;
};

export type CommuneWhereUniqueInput = {
	id?: InputMaybe<Scalars["ID"]>;
};

export type CreateInitialUserInput = {
	email?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	password?: InputMaybe<Scalars["String"]>;
};

export type FloatNullableFilter = {
	equals?: InputMaybe<Scalars["Float"]>;
	gt?: InputMaybe<Scalars["Float"]>;
	gte?: InputMaybe<Scalars["Float"]>;
	in?: InputMaybe<Array<Scalars["Float"]>>;
	lt?: InputMaybe<Scalars["Float"]>;
	lte?: InputMaybe<Scalars["Float"]>;
	not?: InputMaybe<FloatNullableFilter>;
	notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type IdFilter = {
	equals?: InputMaybe<Scalars["ID"]>;
	gt?: InputMaybe<Scalars["ID"]>;
	gte?: InputMaybe<Scalars["ID"]>;
	in?: InputMaybe<Array<Scalars["ID"]>>;
	lt?: InputMaybe<Scalars["ID"]>;
	lte?: InputMaybe<Scalars["ID"]>;
	not?: InputMaybe<IdFilter>;
	notIn?: InputMaybe<Array<Scalars["ID"]>>;
};

export type KeystoneAdminMeta = {
	__typename?: "KeystoneAdminMeta";
	enableSessionItem: Scalars["Boolean"];
	enableSignout: Scalars["Boolean"];
	list?: Maybe<KeystoneAdminUiListMeta>;
	lists: Array<KeystoneAdminUiListMeta>;
};

export type KeystoneAdminMetaListArgs = {
	key: Scalars["String"];
};

export type KeystoneAdminUiFieldMeta = {
	__typename?: "KeystoneAdminUIFieldMeta";
	createView: KeystoneAdminUiFieldMetaCreateView;
	customViewsIndex?: Maybe<Scalars["Int"]>;
	fieldMeta?: Maybe<Scalars["JSON"]>;
	isFilterable: Scalars["Boolean"];
	isOrderable: Scalars["Boolean"];
	itemView?: Maybe<KeystoneAdminUiFieldMetaItemView>;
	label: Scalars["String"];
	listView: KeystoneAdminUiFieldMetaListView;
	path: Scalars["String"];
	search?: Maybe<QueryMode>;
	viewsIndex: Scalars["Int"];
};

export type KeystoneAdminUiFieldMetaItemViewArgs = {
	id?: InputMaybe<Scalars["ID"]>;
};

export type KeystoneAdminUiFieldMetaCreateView = {
	__typename?: "KeystoneAdminUIFieldMetaCreateView";
	fieldMode: KeystoneAdminUiFieldMetaCreateViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaCreateViewFieldMode {
	Edit = "edit",
	Hidden = "hidden",
}

export type KeystoneAdminUiFieldMetaItemView = {
	__typename?: "KeystoneAdminUIFieldMetaItemView";
	fieldMode?: Maybe<KeystoneAdminUiFieldMetaItemViewFieldMode>;
};

export enum KeystoneAdminUiFieldMetaItemViewFieldMode {
	Edit = "edit",
	Hidden = "hidden",
	Read = "read",
}

export type KeystoneAdminUiFieldMetaListView = {
	__typename?: "KeystoneAdminUIFieldMetaListView";
	fieldMode: KeystoneAdminUiFieldMetaListViewFieldMode;
};

export enum KeystoneAdminUiFieldMetaListViewFieldMode {
	Hidden = "hidden",
	Read = "read",
}

export type KeystoneAdminUiListMeta = {
	__typename?: "KeystoneAdminUIListMeta";
	description?: Maybe<Scalars["String"]>;
	fields: Array<KeystoneAdminUiFieldMeta>;
	hideCreate: Scalars["Boolean"];
	hideDelete: Scalars["Boolean"];
	initialColumns: Array<Scalars["String"]>;
	initialSort?: Maybe<KeystoneAdminUiSort>;
	isHidden: Scalars["Boolean"];
	itemQueryName: Scalars["String"];
	key: Scalars["String"];
	label: Scalars["String"];
	labelField: Scalars["String"];
	listQueryName: Scalars["String"];
	pageSize: Scalars["Int"];
	path: Scalars["String"];
	plural: Scalars["String"];
	singular: Scalars["String"];
};

export type KeystoneAdminUiSort = {
	__typename?: "KeystoneAdminUISort";
	direction: KeystoneAdminUiSortDirection;
	field: Scalars["String"];
};

export enum KeystoneAdminUiSortDirection {
	Asc = "ASC",
	Desc = "DESC",
}

export type KeystoneMeta = {
	__typename?: "KeystoneMeta";
	adminMeta: KeystoneAdminMeta;
};

export type Mutation = {
	__typename?: "Mutation";
	authenticateUserWithPassword?: Maybe<UserAuthenticationWithPasswordResult>;
	createColline?: Maybe<Colline>;
	createCollines?: Maybe<Array<Maybe<Colline>>>;
	createCommune?: Maybe<Commune>;
	createCommunes?: Maybe<Array<Maybe<Commune>>>;
	createInitialUser: UserAuthenticationWithPasswordSuccess;
	createProvince?: Maybe<Province>;
	createProvinces?: Maybe<Array<Maybe<Province>>>;
	createUser?: Maybe<User>;
	createUsers?: Maybe<Array<Maybe<User>>>;
	deleteColline?: Maybe<Colline>;
	deleteCollines?: Maybe<Array<Maybe<Colline>>>;
	deleteCommune?: Maybe<Commune>;
	deleteCommunes?: Maybe<Array<Maybe<Commune>>>;
	deleteProvince?: Maybe<Province>;
	deleteProvinces?: Maybe<Array<Maybe<Province>>>;
	deleteUser?: Maybe<User>;
	deleteUsers?: Maybe<Array<Maybe<User>>>;
	endSession: Scalars["Boolean"];
	updateColline?: Maybe<Colline>;
	updateCollines?: Maybe<Array<Maybe<Colline>>>;
	updateCommune?: Maybe<Commune>;
	updateCommunes?: Maybe<Array<Maybe<Commune>>>;
	updateProvince?: Maybe<Province>;
	updateProvinces?: Maybe<Array<Maybe<Province>>>;
	updateUser?: Maybe<User>;
	updateUsers?: Maybe<Array<Maybe<User>>>;
};

export type MutationAuthenticateUserWithPasswordArgs = {
	email: Scalars["String"];
	password: Scalars["String"];
};

export type MutationCreateCollineArgs = {
	data: CollineCreateInput;
};

export type MutationCreateCollinesArgs = {
	data: Array<CollineCreateInput>;
};

export type MutationCreateCommuneArgs = {
	data: CommuneCreateInput;
};

export type MutationCreateCommunesArgs = {
	data: Array<CommuneCreateInput>;
};

export type MutationCreateInitialUserArgs = {
	data: CreateInitialUserInput;
};

export type MutationCreateProvinceArgs = {
	data: ProvinceCreateInput;
};

export type MutationCreateProvincesArgs = {
	data: Array<ProvinceCreateInput>;
};

export type MutationCreateUserArgs = {
	data: UserCreateInput;
};

export type MutationCreateUsersArgs = {
	data: Array<UserCreateInput>;
};

export type MutationDeleteCollineArgs = {
	where: CollineWhereUniqueInput;
};

export type MutationDeleteCollinesArgs = {
	where: Array<CollineWhereUniqueInput>;
};

export type MutationDeleteCommuneArgs = {
	where: CommuneWhereUniqueInput;
};

export type MutationDeleteCommunesArgs = {
	where: Array<CommuneWhereUniqueInput>;
};

export type MutationDeleteProvinceArgs = {
	where: ProvinceWhereUniqueInput;
};

export type MutationDeleteProvincesArgs = {
	where: Array<ProvinceWhereUniqueInput>;
};

export type MutationDeleteUserArgs = {
	where: UserWhereUniqueInput;
};

export type MutationDeleteUsersArgs = {
	where: Array<UserWhereUniqueInput>;
};

export type MutationUpdateCollineArgs = {
	data: CollineUpdateInput;
	where: CollineWhereUniqueInput;
};

export type MutationUpdateCollinesArgs = {
	data: Array<CollineUpdateArgs>;
};

export type MutationUpdateCommuneArgs = {
	data: CommuneUpdateInput;
	where: CommuneWhereUniqueInput;
};

export type MutationUpdateCommunesArgs = {
	data: Array<CommuneUpdateArgs>;
};

export type MutationUpdateProvinceArgs = {
	data: ProvinceUpdateInput;
	where: ProvinceWhereUniqueInput;
};

export type MutationUpdateProvincesArgs = {
	data: Array<ProvinceUpdateArgs>;
};

export type MutationUpdateUserArgs = {
	data: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type MutationUpdateUsersArgs = {
	data: Array<UserUpdateArgs>;
};

export type NestedStringFilter = {
	contains?: InputMaybe<Scalars["String"]>;
	endsWith?: InputMaybe<Scalars["String"]>;
	equals?: InputMaybe<Scalars["String"]>;
	gt?: InputMaybe<Scalars["String"]>;
	gte?: InputMaybe<Scalars["String"]>;
	in?: InputMaybe<Array<Scalars["String"]>>;
	lt?: InputMaybe<Scalars["String"]>;
	lte?: InputMaybe<Scalars["String"]>;
	not?: InputMaybe<NestedStringFilter>;
	notIn?: InputMaybe<Array<Scalars["String"]>>;
	startsWith?: InputMaybe<Scalars["String"]>;
};

export type NestedStringNullableFilter = {
	contains?: InputMaybe<Scalars["String"]>;
	endsWith?: InputMaybe<Scalars["String"]>;
	equals?: InputMaybe<Scalars["String"]>;
	gt?: InputMaybe<Scalars["String"]>;
	gte?: InputMaybe<Scalars["String"]>;
	in?: InputMaybe<Array<Scalars["String"]>>;
	lt?: InputMaybe<Scalars["String"]>;
	lte?: InputMaybe<Scalars["String"]>;
	not?: InputMaybe<NestedStringNullableFilter>;
	notIn?: InputMaybe<Array<Scalars["String"]>>;
	startsWith?: InputMaybe<Scalars["String"]>;
};

export enum OrderDirection {
	Asc = "asc",
	Desc = "desc",
}

export type PasswordState = {
	__typename?: "PasswordState";
	isSet: Scalars["Boolean"];
};

export type Province = {
	__typename?: "Province";
	communes?: Maybe<Array<Commune>>;
	communesCount?: Maybe<Scalars["Int"]>;
	id: Scalars["ID"];
	latitude?: Maybe<Scalars["Float"]>;
	longitude?: Maybe<Scalars["Float"]>;
	name?: Maybe<Scalars["String"]>;
};

export type ProvinceCommunesArgs = {
	orderBy?: Array<CommuneOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: CommuneWhereInput;
};

export type ProvinceCommunesCountArgs = {
	where?: CommuneWhereInput;
};

export type ProvinceCreateInput = {
	communes?: InputMaybe<CommuneRelateToManyForCreateInput>;
	latitude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
};

export type ProvinceOrderByInput = {
	id?: InputMaybe<OrderDirection>;
	latitude?: InputMaybe<OrderDirection>;
	longitude?: InputMaybe<OrderDirection>;
	name?: InputMaybe<OrderDirection>;
};

export type ProvinceRelateToOneForCreateInput = {
	connect?: InputMaybe<ProvinceWhereUniqueInput>;
	create?: InputMaybe<ProvinceCreateInput>;
};

export type ProvinceRelateToOneForUpdateInput = {
	connect?: InputMaybe<ProvinceWhereUniqueInput>;
	create?: InputMaybe<ProvinceCreateInput>;
	disconnect?: InputMaybe<Scalars["Boolean"]>;
};

export type ProvinceUpdateArgs = {
	data: ProvinceUpdateInput;
	where: ProvinceWhereUniqueInput;
};

export type ProvinceUpdateInput = {
	communes?: InputMaybe<CommuneRelateToManyForUpdateInput>;
	latitude?: InputMaybe<Scalars["Float"]>;
	longitude?: InputMaybe<Scalars["Float"]>;
	name?: InputMaybe<Scalars["String"]>;
};

export type ProvinceWhereInput = {
	AND?: InputMaybe<Array<ProvinceWhereInput>>;
	NOT?: InputMaybe<Array<ProvinceWhereInput>>;
	OR?: InputMaybe<Array<ProvinceWhereInput>>;
	communes?: InputMaybe<CommuneManyRelationFilter>;
	id?: InputMaybe<IdFilter>;
	latitude?: InputMaybe<FloatNullableFilter>;
	longitude?: InputMaybe<FloatNullableFilter>;
	name?: InputMaybe<StringFilter>;
};

export type ProvinceWhereUniqueInput = {
	id?: InputMaybe<Scalars["ID"]>;
};

export type Query = {
	__typename?: "Query";
	authenticatedItem?: Maybe<AuthenticatedItem>;
	colline?: Maybe<Colline>;
	collines?: Maybe<Array<Colline>>;
	collinesCount?: Maybe<Scalars["Int"]>;
	commune?: Maybe<Commune>;
	communes?: Maybe<Array<Commune>>;
	communesCount?: Maybe<Scalars["Int"]>;
	keystone: KeystoneMeta;
	province?: Maybe<Province>;
	provinces?: Maybe<Array<Province>>;
	provincesCount?: Maybe<Scalars["Int"]>;
	user?: Maybe<User>;
	users?: Maybe<Array<User>>;
	usersCount?: Maybe<Scalars["Int"]>;
};

export type QueryCollineArgs = {
	where: CollineWhereUniqueInput;
};

export type QueryCollinesArgs = {
	orderBy?: Array<CollineOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: CollineWhereInput;
};

export type QueryCollinesCountArgs = {
	where?: CollineWhereInput;
};

export type QueryCommuneArgs = {
	where: CommuneWhereUniqueInput;
};

export type QueryCommunesArgs = {
	orderBy?: Array<CommuneOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: CommuneWhereInput;
};

export type QueryCommunesCountArgs = {
	where?: CommuneWhereInput;
};

export type QueryProvinceArgs = {
	where: ProvinceWhereUniqueInput;
};

export type QueryProvincesArgs = {
	orderBy?: Array<ProvinceOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: ProvinceWhereInput;
};

export type QueryProvincesCountArgs = {
	where?: ProvinceWhereInput;
};

export type QueryUserArgs = {
	where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
	orderBy?: Array<UserOrderByInput>;
	skip?: Scalars["Int"];
	take?: InputMaybe<Scalars["Int"]>;
	where?: UserWhereInput;
};

export type QueryUsersCountArgs = {
	where?: UserWhereInput;
};

export enum QueryMode {
	Default = "default",
	Insensitive = "insensitive",
}

export type StringFilter = {
	contains?: InputMaybe<Scalars["String"]>;
	endsWith?: InputMaybe<Scalars["String"]>;
	equals?: InputMaybe<Scalars["String"]>;
	gt?: InputMaybe<Scalars["String"]>;
	gte?: InputMaybe<Scalars["String"]>;
	in?: InputMaybe<Array<Scalars["String"]>>;
	lt?: InputMaybe<Scalars["String"]>;
	lte?: InputMaybe<Scalars["String"]>;
	mode?: InputMaybe<QueryMode>;
	not?: InputMaybe<NestedStringFilter>;
	notIn?: InputMaybe<Array<Scalars["String"]>>;
	startsWith?: InputMaybe<Scalars["String"]>;
};

export type StringNullableFilter = {
	contains?: InputMaybe<Scalars["String"]>;
	endsWith?: InputMaybe<Scalars["String"]>;
	equals?: InputMaybe<Scalars["String"]>;
	gt?: InputMaybe<Scalars["String"]>;
	gte?: InputMaybe<Scalars["String"]>;
	in?: InputMaybe<Array<Scalars["String"]>>;
	lt?: InputMaybe<Scalars["String"]>;
	lte?: InputMaybe<Scalars["String"]>;
	mode?: InputMaybe<QueryMode>;
	not?: InputMaybe<NestedStringNullableFilter>;
	notIn?: InputMaybe<Array<Scalars["String"]>>;
	startsWith?: InputMaybe<Scalars["String"]>;
};

export type User = {
	__typename?: "User";
	email?: Maybe<Scalars["String"]>;
	id: Scalars["ID"];
	name?: Maybe<Scalars["String"]>;
	password?: Maybe<PasswordState>;
};

export type UserAuthenticationWithPasswordFailure = {
	__typename?: "UserAuthenticationWithPasswordFailure";
	message: Scalars["String"];
};

export type UserAuthenticationWithPasswordResult =
	| UserAuthenticationWithPasswordFailure
	| UserAuthenticationWithPasswordSuccess;

export type UserAuthenticationWithPasswordSuccess = {
	__typename?: "UserAuthenticationWithPasswordSuccess";
	item: User;
	sessionToken: Scalars["String"];
};

export type UserCreateInput = {
	email?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	password?: InputMaybe<Scalars["String"]>;
};

export type UserOrderByInput = {
	email?: InputMaybe<OrderDirection>;
	id?: InputMaybe<OrderDirection>;
	name?: InputMaybe<OrderDirection>;
};

export type UserUpdateArgs = {
	data: UserUpdateInput;
	where: UserWhereUniqueInput;
};

export type UserUpdateInput = {
	email?: InputMaybe<Scalars["String"]>;
	name?: InputMaybe<Scalars["String"]>;
	password?: InputMaybe<Scalars["String"]>;
};

export type UserWhereInput = {
	AND?: InputMaybe<Array<UserWhereInput>>;
	NOT?: InputMaybe<Array<UserWhereInput>>;
	OR?: InputMaybe<Array<UserWhereInput>>;
	email?: InputMaybe<StringFilter>;
	id?: InputMaybe<IdFilter>;
	name?: InputMaybe<StringFilter>;
};

export type UserWhereUniqueInput = {
	email?: InputMaybe<Scalars["String"]>;
	id?: InputMaybe<Scalars["ID"]>;
};

export type GetDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetDataQuery = {
	__typename?: "Query";
	provinces?: Array<{
		__typename?: "Province";
		id: string;
		name?: string | null;
		latitude?: number | null;
		longitude?: number | null;
		communesCount?: number | null;
	}> | null;
	communes?: Array<{
		__typename?: "Commune";
		id: string;
		name?: string | null;
		latitude?: number | null;
		longitude?: number | null;
		collinesCount?: number | null;
		collines?: Array<{
			__typename?: "Colline";
			id: string;
			name?: string | null;
			cooperativeName?: string | null;
			NIF?: string | null;
			RC?: string | null;
			rating?: string | null;
			description?: string | null;
			adminName?: string | null;
			treasury?: number | null;
			achievable?: number | null;
			amount?: number | null;
			total?: number | null;
			accounting?: string | null;
			books?: string | null;
			latidude?: number | null;
			longitude?: number | null;
		}> | null;
		province?: {
			__typename?: "Province";
			id: string;
			name?: string | null;
			communesCount?: number | null;
		} | null;
	}> | null;
	collines?: Array<{
		__typename?: "Colline";
		id: string;
		name?: string | null;
		cooperativeName?: string | null;
		NIF?: string | null;
		RC?: string | null;
		rating?: string | null;
		description?: string | null;
		adminName?: string | null;
		adminPhone?: string | null;
		treasury?: number | null;
		achievable?: number | null;
		amount?: number | null;
		total?: number | null;
		accounting?: string | null;
		books?: string | null;
		latidude?: number | null;
		longitude?: number | null;
		commune?: {
			__typename?: "Commune";
			id: string;
			collinesCount?: number | null;
			name?: string | null;
			province?: {
				__typename?: "Province";
				id: string;
				name?: string | null;
			} | null;
		} | null;
	}> | null;
};

export const GetDataDocument = gql`
	query GetData {
		provinces {
			id
			name
			latitude
			longitude
			communesCount
		}
		communes {
			id
			name
			latitude
			longitude
			collinesCount
			collines {
				id
				name
				cooperativeName
				NIF
				RC
				rating
				description
				adminName
				adminName
				treasury
				achievable
				amount
				total
				accounting
				books
				latidude
				longitude
			}
			province {
				id
				name
				communesCount
			}
		}
		collines {
			id
			name
			cooperativeName
			NIF
			RC
			rating
			description
			adminName
			adminPhone
			treasury
			achievable
			amount
			total
			accounting
			books
			latidude
			longitude
			commune {
				id
				collinesCount
				name
				province {
					id
					name
				}
			}
		}
	}
`;

/**
 * __useGetDataQuery__
 *
 * To run a query within a React component, call `useGetDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDataQuery(
	baseOptions?: Apollo.QueryHookOptions<GetDataQuery, GetDataQueryVariables>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useQuery<GetDataQuery, GetDataQueryVariables>(
		GetDataDocument,
		options
	);
}
export function useGetDataLazyQuery(
	baseOptions?: Apollo.LazyQueryHookOptions<
		GetDataQuery,
		GetDataQueryVariables
	>
) {
	const options = { ...defaultOptions, ...baseOptions };
	return Apollo.useLazyQuery<GetDataQuery, GetDataQueryVariables>(
		GetDataDocument,
		options
	);
}
export type GetDataQueryHookResult = ReturnType<typeof useGetDataQuery>;
export type GetDataLazyQueryHookResult = ReturnType<typeof useGetDataLazyQuery>;
export type GetDataQueryResult = Apollo.QueryResult<
	GetDataQuery,
	GetDataQueryVariables
>;

export const GetData = gql`
	query GetData {
		provinces {
			id
			name
			latitude
			longitude
			communesCount
		}
		communes {
			id
			name
			latitude
			longitude
			collinesCount
			collines {
				id
				name
				cooperativeName
				NIF
				RC
				rating
				description
				adminName
				adminName
				treasury
				achievable
				amount
				total
				accounting
				books
				latidude
				longitude
			}
			province {
				id
				name
				communesCount
			}
		}
		collines {
			id
			name
			cooperativeName
			NIF
			RC
			rating
			description
			adminName
			adminPhone
			treasury
			achievable
			amount
			total
			accounting
			books
			latidude
			longitude
			commune {
				id
				collinesCount
				name
				province {
					id
					name
				}
			}
		}
	}
`;

export interface PossibleTypesResultData {
	possibleTypes: {
		[key: string]: string[];
	};
}
const result: PossibleTypesResultData = {
	possibleTypes: {
		AuthenticatedItem: ["User"],
		UserAuthenticationWithPasswordResult: [
			"UserAuthenticationWithPasswordFailure",
			"UserAuthenticationWithPasswordSuccess",
		],
	},
};
export default result;

import { Box, Navbar } from "@mantine/core";
import { useEffect } from "react";
import useVirtual from "react-cool-virtual";
import { useStore } from "../../store";
import { countRatings } from "../../utils";
import { Colline } from "../../views/colline";

export const MainNav = () => {
	const { collines, setRatingCounts } = useStore((store) => store);

	const { outerRef, innerRef, items } = useVirtual({
		itemCount: collines.length, // Provide the total number for the list items
		itemSize: 400, // The size of each item (400px)
	});

	useEffect(() => {
		return setRatingCounts(countRatings(collines));
	}, [collines, setRatingCounts]);

	return (
		<Navbar.Section
			p="sm"
			grow
			ref={outerRef as any}
			sx={() => ({
				width: "100%",
				height: "700px",
				overflow: "auto",
			})}
		>
			<Box ref={innerRef as any}>
				{items.map(({ index, size }) => {
					const colline = collines[index];

					return (
						<Colline
							my={"xl"}
							p="sm"
							radius="lg"
							sx={(theme) => ({
								height: `${size}px`,
								border: `1px solid ${theme.colors.gray[2]}`,
							})}
							{...colline}
							key={index}
						/>
					);
				})}
			</Box>
		</Navbar.Section>
	);
};

import { Box, Image, Navbar, Select, Stack, Text } from "@mantine/core";
import { showNotification } from "@mantine/notifications";
import { useState } from "react";
import { useStore } from "../../store";
import { tCollines, tCommune, tCommunes, tProvince } from "../../types";

export const SidebarHeader = () => {
	const [communesInProvince, setCommunesInProvince] = useState<tCommunes>([]);
	const [province, setProvince] = useState<tProvince>();
	const {
		provinces,
		communes,
		collines,
		filter,
		setFilter,
		setBreadcrumbs,
		breadcrumbs,
	} = useStore((store) => store);

	const { setCollines } = useStore((store) => store);

	const getPlaceholder = (province: tProvince) => {
		if (province) {
			return `Communes en ${province.name}`;
		}

		return `Tous les communes`;
	};

	const getCommunesData = (isFiltering: boolean) => {
		const selector = ({ id, name }: tCommune) => ({
			value: id,
			label: name as string,
		});

		if (isFiltering) return communesInProvince.map(selector);

		return communes.map(selector);
	};
	return (
		<Navbar.Section>
			<Box
				component="a"
				href="/"
				sx={(theme: any) => ({
					position: "relative",
					display: "flex",
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#303891",
					padding: `${theme.spacing.sm}px 0`,
					textAlign: "center",
					textDecoration: "none",
				})}
			>
				<Image src="/bdi-coa.webp" />

				<Text
					sx={(theme) => ({
						color: theme.colors.violet[0],
						fontWeight: 900,
						textTransform: "uppercase",
						marginLeft: 10,
						fontSize: "20px",
					})}
				>
					Sangwe
				</Text>
			</Box>

			<Stack p="xs">
				<Select
					size="md"
					placeholder="Tous les provinces"
					searchable
					nothingFound="No options"
					maxDropdownHeight={280}
					radius="md"
					clearable
					onChange={(value) => {
						if (value) {
							setFilter({
								isFiltering: true,
								collines,
								breadcrumbs,
							});

							const province = provinces.find(
								({ id }) => id === value
							) as tProvince;

							const filteredCommunes = communes.filter(
								({ province }) => province?.id === value
							);

							const provinceCollines = filteredCommunes
								.map(({ collines }) => [collines as tCollines])
								.flat()
								.flat();

							setCollines(provinceCollines);
							setCommunesInProvince(filteredCommunes);
							setProvince(province);

							return setBreadcrumbs([
								{ title: `${province.name}` },
								{
									title: `${filteredCommunes.length} Communes`,
								},
								{
									title: `${provinceCollines.length} Cooperatives`,
								},
							]);
						} else {
							setCollines(filter.collines);
							setBreadcrumbs(filter.breadcrumbs);
							setProvince(undefined);
							setFilter({
								isFiltering: false,
								collines: [],
								breadcrumbs: [],
							});
						}
					}}
					data={provinces.map(({ id, name }) => ({
						value: id,
						label: name as string,
					}))}
					styles={(theme) => ({
						dropdown: {
							borderRadius: theme.radius.md,
						},
					})}
				/>

				<Select
					clearable
					radius="md"
					searchable
					size="md"
					nothingFound="Aucune donnée disponible"
					maxDropdownHeight={280}
					data={getCommunesData(filter.isFiltering)}
					placeholder={getPlaceholder(province as tProvince)}
					onChange={(value) => {
						if (value && province) {
							const commune = communesInProvince.filter(
								({ id }) => id === value
							)[0];

							setCollines((commune?.collines as tCollines) || []);

							return setBreadcrumbs([
								{ title: `${commune.province?.name}` },
								{ title: `${commune.name}` },
								{
									title: `${commune.collinesCount} Cooperatives`,
								},
							]);
						}

						if (value && !province) {
							return showNotification({
								title: "Action refusée",
								message:
									"Veuillez sélectionner une province pour voir les communes.",
								color: "yellow",
								autoClose: 15000,
							});
						}

						if (!value) {
							const collinesInProvince = communesInProvince
								.map(({ collines }) => [collines as tCollines])
								.flat()
								.flat();

							setCollines(collinesInProvince);

							return setBreadcrumbs([
								{ title: `${province?.name}` },
								{
									title: `${communesInProvince.length} Communes`,
								},
								{
									title: `${collinesInProvince.length} Cooperatives`,
								},
							]);
						}
					}}
					styles={(theme) => ({
						dropdown: {
							borderRadius: theme.radius.md,
							zIndex: 1001,
						},
					})}
				/>
			</Stack>
		</Navbar.Section>
	);
};

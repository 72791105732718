import { AppShell } from "@mantine/core";
import { ReactNode } from "react";
import { MenuBurger } from "../burger";
import { Toolbar } from "../toolbar";
import { Sidebar } from "./sidebar";

type AppLayoutProps = {
	children: ReactNode;
	title?: string;
};

export function AppLayout({ title, children }: AppLayoutProps) {
	return (
		<AppShell
			padding={0}
			navbar={<Sidebar />}
			styles={{ main: { position: "relative" } }}
			fixed
		>
			<MenuBurger />
			{children}
			<Toolbar />
		</AppShell>
	);
}

import { ApolloClient, InMemoryCache } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

const link = new BatchHttpLink({
	uri: "https://sangwe.herokuapp.com/api/graphql",
	batchMax: 5, // No more than 5 operations per batch
	batchInterval: 20, // Wait no more than 20ms after first batched operation
});

export const client = new ApolloClient({
	cache: new InMemoryCache({}),
	link,
});

import { BLUE_ICON, GOLD_ICON, GREEN_ICON, RED_ICON } from "../config";
import { tCollines, tRating } from "../types";

export const getIcon = (name: tRating) => {
	switch (name) {
		case "GREEN":
			return GREEN_ICON;

		case "RED":
			return RED_ICON;

		case "ORANGE":
			return GOLD_ICON;
		default:
			return BLUE_ICON;
	}
};

export const formatMoney = (
	amount: any,
	currency: any = "BIF",
	locale: any = "fr-FR"
) => {
	return new Intl.NumberFormat(locale, {
		style: "currency",
		currency,
	}).format(amount);
};

export const countRatings = (
	collines: tCollines
): { red: number; orange: number; green: number } => {
	const green = collines.filter(({ rating }) => rating === "GREEN").length;

	const orange = collines.filter(({ rating }) => rating === "ORANGE").length;

	const red = collines.filter(({ rating }) => rating === "RED").length;

	return { green, orange, red };
};
